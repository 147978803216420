<template>
  <div>
    <v-card elevation="1">
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
        <v-col lg="3" md="3" sm="12" class="pb-0">
          <v-text-field
            label="Search Orders"
            append-icon="search"
            class="pb-0 pt-2"
            v-model="search"
            @keyup="getOrderList"
          ></v-text-field>
        </v-col>
      </v-card-actions>
      <v-divider class="py-0 my-0"></v-divider>

      <v-data-table
        :headers="headers"
        :items="orderList"
        :server-items-length="totalOrderItem"
        :options.sync="options"
      >
        <template v-slot:[`item.indexNo`]="{ item }">
          {{
            (options.page - 1) * options.itemsPerPage +
            (orderList.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.orderDate`]="{ item }">
          {{ moment(item.orderDate).format("MMMM Do YYYY, h:mm:ss a") }}
        </template>
        <template v-slot:[`item.viewAction`]="{ item }">
          <v-icon @click="viewOrder(item)" color="info">visibility</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <loading :active="isLoading" :loader="loader" />
    </v-row>
  </div>
</template>

<script>
import orderAPI from "@/services/orderAPI.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      //fullPage: false,
      loader: "bars",
      moment: moment,
      search: "",
      options: {},
      totalOrderItem: 0,
      headers: [
        {
          text: "#",
          value: "indexNo",
          sortable: false,
        },
        {
          text: "Order Id",
          value: "orderId",
          align: "center",
          filterable: true,
        },
        {
          text: "Order Type",
          value: "orderType",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "statusId",
          align: "center",
          sortable: true,
        },
        {
          text: "Created Date",
          value: "orderDate",
          align: "center",
          sortable: true,
        },
        {
          text: "Grand Total",
          value: "grandTotal",
          align: "center",
          sortable: true,
        },
        {
          text: "View Order Detail",
          value: "viewAction",
          align: "center",
          sortable: false,
        },
      ],
      orderList: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.getOrderList();
      },
      deep: true,
    },
  },
  methods: {
    async getOrderList() {
      this.isLoading = true;
      let perPageSize = "";
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        perPageSize = this.totalOrderItem;
      } else {
        perPageSize = itemsPerPage;
      }
      try {
        let response = await orderAPI.getOrderList(
          page,
          perPageSize,
          this.search
        );
        this.isLoading = false;
        this.totalOrderItem = response.listSize;
        this.orderList = response.orderList;
      } catch (error) {
        this.isLoading = false;
        console.log("====error===", error);
      }
    },
    viewOrder(item) {
      this.isLoading = true;
      this.$router.push(`/order/${item.orderId}`);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>